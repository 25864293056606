.container {
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
    overflow: scroll
  }

  .userInfo {
    text-align: right;
    padding: 30px 120px;
    margin: 5px 0;
  }